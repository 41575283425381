@import "_mixins.scss";
@import "_hero.scss";
@import "_footer_form.scss";

$home-title-font-size: (null: 22px, $md: 22px, $sm: 17px, $xs: 17px);

.main-hero-brochure {
    @extend %hero-brochure;
}

.title {
    @include property-map("font-size", $home-title-font-size);
    font-weight   : bold;
    letter-spacing: -0.4px;
    color         : #ab9352;
    text-align    : center;
    margin-bottom : 15px;
}

.container {
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}

.section-banner {
    margin-bottom: 50px;

    @media screen and (max-width: 991px) {
        margin-bottom : 40px;
        padding-bottom: 40px;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }

    .container {
        display        : flex;
        justify-content: center;
        flex-direction : column;
        align-items    : center;
        padding        : 0;
        margin         : 0;
        width          : 100%;
        position       : relative;

        img {
            width: 100%;
        }

        .desktop-banner {
            display: block;

            @media screen and (max-width: 575px) {
                display: none !important;
            }
        }

        .mobile-banner {
            display: none !important;

            @media screen and (max-width: 575px) {
                display: block !important;
            }
        }

        map {
            z-index: 99999;

            area {
                z-index: 99999;
            }
        }

        .infos {
            padding       : em(15);
            opacity       : 0.9;
            display       : flex;
            flex-direction: column;
            align-items   : center;

            @media screen and (min-width: 576px) {
                position   : absolute;
                top        : 10%;
                left       : em(20);
                z-index    : 2;
                align-items: flex-start;
            }

            .hero-title {
                color        : #ffffff;
                margin-bottom: em(20);
                font-family  : 'Comfortaa', cursive;
                text-shadow  : 2px 2px 5px #000000;
                @include property-map("font-size", $hero-title-font-size);

                @media screen and (max-width: 575px) {
                    color      : #014635;
                    text-align : center;
                    text-shadow: unset;
                }
            }

            %hero-brochure {
                display         : flex;
                justify-content : center;
                align-items     : center;
                width           : em(179);
                height          : em(50);
                border-radius   : em(2);
                border          : solid em(2) #d0c393;
                background-color: #014635;
                font-size       : em(14);
                font-weight     : bold;
                color           : #d0c393;
                text-decoration : none;
            }

            .hero-box {
                z-index       : 9;
                display       : flex;
                flex-direction: row;
                bottom        : 23%;
                align-items   : center;
                align-self    : center;

                @media screen and (max-width: 991px) {
                    flex-direction: column;
                    width         : 80%;
                    bottom        : unset;
                    top           : em(240);
                    align-self    : flex-start;
                }

                .hero-black-box {
                    display: flex;

                    @media screen and (max-width: 991px) {
                        width         : 100%;
                        flex-direction: column;
                    }

                    .box-1 {
                        border-top-left-radius   : em(5);
                        border-bottom-left-radius: em(5);

                        @media screen and (max-width: 991px) {
                            border-bottom-left-radius: unset;
                            border-top-right-radius  : em(5);
                        }
                    }

                    .box-3 {
                        border-right: unset;

                        @media screen and (max-width: 991px) {
                            border-right: solid em(1) #d0c393;
                        }
                    }
                }

                .footer-box {
                    width           : em(207);
                    height          : em(84);
                    background-color: rgba(1, 70, 53, 0.8);
                    display         : flex;
                    flex-direction  : column;
                    justify-content : center;
                    align-items     : center;
                    border          : solid em(1) #d0c393;
                    font-size       : em(18);
                    font-weight     : 300;
                    color           : #ffffff;

                    @media screen and (max-width: 1199px) {
                        width: em(170);
                    }

                    @media screen and (max-width: 991px) {
                        width          : 100%;
                        height         : em(60);
                        line-height    : em(23);
                        flex-direction : row;
                        justify-content: flex-start;
                        padding-left   : em(20);
                        border-bottom  : unset;
                    }

                    @media screen and (max-width: 575px) {
                        font-size: em(15);
                    }

                    strong {
                        font-weight: bold;
                        color      : #fff;
                    }
                }

                .last-box {
                    width           : em(280);
                    height          : em(133);
                    background-color: #fff;
                    color           : #808080;
                    border-radius   : em(5);
                    border          : solid em(1) #979797;
                    font-size       : em(20);
                    color           : #014635 !important;

                    @media screen and (max-width: 991px) {
                        width                  : 100%;
                        height                 : em(80);
                        flex-direction         : row;
                        align-items            : center;
                        border-top-left-radius : unset;
                        border-top-right-radius: unset;
                        padding-left           : em(20);
                    }

                    @media screen and (max-width: 575px) {
                        font-size: em(18);
                    }

                    .last-footer {
                        display       : flex;
                        flex-direction: column;

                        @media screen and (max-width: 991px) {}

                        .starting {
                            text-align: left;
                        }

                        .price {
                            font-size: em(30);

                            @media screen and (max-width: 575px) {
                                font-size: em(20);
                            }

                            strong {
                                font-weight: bold;
                                color      : #014635;
                            }
                        }
                    }
                }
            }
        }
    }
}

.section-description {
    .container {
        height         : 100%;
        display        : flex;
        justify-content: center;
        align-items    : center;
        flex-direction : column;

        .title {
            font-weight   : 500;
            font-style    : normal;
            font-stretch  : normal;
            line-height   : 44px;
            letter-spacing: normal;
            text-align    : center;
            color         : #B59233;
            margin-bottom : 20px;
            font-size     : 36px;

            // @include property-map("font-size", $title-font-size);
            @media screen and (max-width: 575px) {
                font-weight: bold;
                font-size  : 18px;
                line-height: 22px;
            }
        }

        .description {
            font-family  : 'Helvetica Neue', sans-serif;
            font-weight  : 500;
            text-align   : center;
            max-height   : 265px;
            line-height  : 22px;
            color        : #333333;
            font-size    : 16px;
            font-style   : normal;
            margin-bottom: 40px;

            @media (min-width: 1200px) {
                max-width: 80%;
            }

            @media screen and (max-width: 575px) {
                font-style : normal;
                font-weight: normal;
                font-size  : 11px;
                line-height: 15px;
                padding: 0 30px;
            }

            // @include property-map("font-size", $description-font-size);
        }
    }
}


.section-about {
    margin-bottom : 50px;
    padding-bottom: 50px;

    @media screen and (max-width: 991px) {
        margin-bottom : 40px;
        padding-bottom: 40px;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }

    .container {
        padding        : 0;
        display        : flex;
        justify-content: center;
        flex-direction : column;
        align-items    : center;

        .title {
            font-family: 'Helvetica Neue', sans-serif;
            font-style : normal;
            font-weight: bold;
            font-size  : 36px;
            line-height: 44px;
            /* identical to box height */

            text-align           : center;
            text-transform       : uppercase;
            font-feature-settings: 'cpsp'on;

            /* Gold Text Title */

            color: #B08D23;

            @media screen and (max-width: 991px) {
                font-size    : 20px;
                margin-bottom: 30px;
            }
        }

        .about-description {
            font-family: 'Helvetica Neue', sans-serif;
            font-style : normal;
            font-weight: 500;
            font-size  : 16px;
            line-height: 22px;
            text-align : center;
            color      : #333333;
            margin     : 0 15px 15px 15px;

            @media (min-width: 1200px) {
                max-width: 80%;
            }

            @media screen and (max-width: 991px) {
                font-size: 12px;
            }
        }

        .about-us {
            width  : 100%;
            display: flex;

            .slick-dots {
                li {
                    button:before {
                        font-size: 15px;
                        color    : #c4c4c4;
                        opacity  : 1 !important;
                    }
                }

                .slick-active {
                    button:before {
                        font-size: 15px;
                        color    : #B59133;
                        opacity  : 1 !important;
                    }
                }
            }

            .about:focus {
                outline: none;
            }

            .about {
                display       : flex;
                flex-direction: column;
                min-width     : 20%;
                height        : 100%;
                align-items   : center;

                @media screen and (max-width: 767px) {
                    min-width : unset;
                    padding   : 0px 10px;
                    opacity   : 0.5;
                    transition: 0.5s;
                }

                .d-block {
                    height         : 100%;
                    width          : 100%;
                    object-fit     : cover;
                    object-position: center center;
                    height         : 50vh;

                    @media screen and (max-width: 991px) {
                        height: 220px;
                    }

                    @media screen and (max-width: 767px) {
                        height: 350px;
                    }
                }

                .about-title {
                    font-family   : 'Helvetica Neue', sans-serif;
                    font-style    : normal;
                    font-weight   : bold;
                    font-size     : 18px;
                    line-height   : 22px;
                    text-align    : center;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;

                    /* Black Text */

                    color      : #333333;
                    padding-top: 25px;

                    @media screen and (max-width: 991px) {
                        padding-top: 15px;
                        text-align : center;
                        font-size  : 13px;
                    }

                    @media screen and (max-width: 767px) {
                        display    : none;
                        font-family: 'Helvetica Neue', sans-serif;
                        font-style : normal;
                        font-weight: bold;
                        font-size  : 18px;
                        line-height: 22px;
                        color      : #666666;
                    }
                }

                .about-home-desc {
                    font-size  : 14px;
                    font-weight: 300;
                    color      : #000000;
                    text-align : center;
                    padding    : 10px;

                    @media screen and (max-width: 991px) {
                        font-size: 11px;
                    }

                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }
            }

            .slick-current {
                opacity: 1;

                .about-title,
                .about-home-desc {
                    display: block;
                    width  : 100vw;
                }
            }
        }
    }
}

.section-clusters {
    @media screen and (max-width: 991px) {
        margin-bottom : 40px;
        padding-bottom: 40px;
    }

    @media screen and (max-width: 767px) {
        margin-bottom      : 0;
        background-image   : url('../../../../files/static/pattern2.jpg');
        height             : 100%;
        background-position: center;
        background-repeat  : no-repeat;
        background-size    : cover;
        padding-bottom     : unset;
    }

    .container {
        padding: 0;

        @media screen and (max-width: 767px) {
            padding-top: 30px;
        }

        .title {
            font-family          : 'Helvetica Neue', sans-serif;
            font-style           : normal;
            font-weight          : 500;
            font-size            : 36px;
            line-height          : 44px;
            text-align           : center;
            text-transform       : uppercase;
            font-feature-settings: 'cpsp'on;
            color                : #B08D23;
            margin-bottom        : 40px;

            @media screen and (max-width: 991px) {
                font-size    : 20px;
                margin-bottom: 30px;
            }

            @media screen and (max-width: 575px) {
                font-weight: bold;
                font-size  : 18px;
                line-height: 22px;
                color      : #FFFFFF;
            }
        }

        .cluster-description {
            font-size  : 14px;
            font-weight: 300;
            text-align : center;
            color      : #000000;
            margin     : 0 15px 15px 15px;

            @media screen and (max-width: 991px) {
                font-size: 12px;
            }
        }

        .masonry {
            display              : grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows   : repeat(12, 1fr);
            grid-gap             : 0;

            @media screen and (max-width: 767px) {
                display: block;
            }
        }

        // .masonry::before,
        // .masonry::after {
        //   content: "";
        //   flex-basis: 100%;
        //   width: 0;
        //   order: 2;
        // }
        .masonry-brick {
            // padding: 5px;
            position: relative;

            .mason-container {
                position: relative;
                height  : 100%;
                width   : 100%;

                .masonry-brick-img {
                    width     : 100%;
                    height    : 100%;
                    object-fit: cover;
                }

                .img-title {
                    margin-top        : -28px;
                    text-align        : end;
                    background-color  : color_theme();
                    position          : absolute;
                    bottom            : 0;
                    right             : 0;
                    width             : 100%;
                    color             : #fff;
                    // padding-right: 5px;
                    display           : flex;
                    align-items       : center;
                    // justify-content: flex-end;
                    justify-content   : center;
                    height            : 40px;
                }
            }
        }

        /* one item */
        .masonry-brick:first-child:nth-last-child(1) {
            width : 100%;
            height: 100%;
        }

        /* two items */
        .masonry-brick:first-child:nth-last-child(2),
        .masonry-brick:first-child:nth-last-child(2)~.masonry-brick {
            width : 50%;
            height: 100%;

            @media screen and (max-width: 767px) {
                width : 100%;
                height: 50%;
            }
        }

        /* three items */
        .masonry-brick:first-child:nth-last-child(3),
        .masonry-brick:first-child:nth-last-child(3)~.masonry-brick {
            width: 33.3333%;
        }

        /* four items */
        .masonry-brick:nth-child(1):nth-last-child(4) {
            width : 25%;
            height: 50%;
        }

        .masonry-brick:nth-child(2):nth-last-child(3) {
            width : 25%;
            height: 50%;
        }

        .masonry-brick:nth-child(3):nth-last-child(2) {
            width : 37.5%;
            height: 100%;
        }

        .masonry-brick:nth-child(4):nth-last-child(1) {
            width : 37.5%;
            height: 100%;
        }

        /* five items */
        .masonry-brick:nth-child(1):nth-last-child(5) {
            // width: 50%;
            // height: 100%;
            grid-column: span 4;
            grid-row   : span 6;
        }

        .masonry-brick:nth-child(2):nth-last-child(4) {
            // width: 25%;
            // height: 50%;
            grid-column: span 4;
            grid-row   : span 6;
        }

        .masonry-brick:nth-child(3):nth-last-child(3) {
            // width: 25%;
            // height: 50%;
            grid-column: span 4;
            grid-row   : span 6;
        }

        .masonry-brick:nth-child(4):nth-last-child(2) {
            // width: 25%;
            // height: 50%;
            grid-column: span 6;
            grid-row   : span 6;
        }

        .masonry-brick:nth-child(5):nth-last-child(1) {
            // width: 25%;
            // height: 50%;
            grid-column: span 6;
            grid-row   : span 6;
        }

        .clusters {
            display        : flex;
            flex-flow: wrap;
            // justify-content: center;
            // width          : 100%;
            // height         : 49vh;


            .slick-track {
                height: 100%;
            }

            .slick-dots {
                margin: -10px 0px;

                li {
                    button:before {
                        font-size: 15px;
                        color    : #c4c4c4;
                        opacity  : 1 !important;
                    }
                }

                .slick-active {
                    button:before {
                        font-size: 15px;
                        color    : #B59133;
                        opacity  : 1 !important;
                    }
                }
            }

            .cluster:hover {
                .cluster-title {
                    // background-color: rgba(255, 255, 255, 0.5);
                    background-color: rgba(176, 141, 35, 0.6);
                    height          : 15%;
                }

                .cluster-view {
                    visibility: visible;
                    opacity   : 1;
                }
            }

            .cluster {
                height               : 50vh;
                width            : 33.33%;
                background-size      : cover;
                background-position  : center center;
                display              : flex;
                position             : relative;
                justify-content      : center;
                align-items          : center;
                -webkit-touch-callout: none;
                /* iOS Safari */
                -webkit-user-select  : none;
                /* Safari */
                -khtml-user-select   : none;
                /* Konqueror HTML */
                -moz-user-select     : none;
                /* Firefox */
                -ms-user-select      : none;
                /* Internet Explorer/Edge */
                user-select          : none;
                cursor: hover;

                /* Non-prefixed version, currently */
                @media screen and (max-width: 767px) {
                    min-width: unset;
                }

                .cluster-title {
                    font-family        : 'Helvetica Neue', sans-serif;
                    font-style         : normal;
                    font-weight        : bold;
                    font-size          : 18px;
                    line-height        : 22px;
                    text-align         : center;
                    letter-spacing     : 0.1em;
                    text-transform     : uppercase;
                    color              : #FFFFFF;
                    text-align         : center;
                    width              : 100%;
                    height             : 100%;
                    display            : flex;
                    justify-content    : center;
                    align-items        : center;
                    // background-color: rgba(208, 195, 147, 0.6);
                    background-color   : rgba(176, 141, 35, 0.4);
                    transition         : .5s;
                    margin-bottom      : 0;
                    text-transform     : uppercase;

                    @media screen and (max-width: 767px) {
                        background-color: rgba(176, 141, 35, 0.5);
                        height          : 15%;
                        width           : 100%;
                        font-size       : 13px;
                        line-height     : 16px;
                        color           : #FFFFFF;
                    }
                }

                .cluster-view {
                    padding         : 15px 20px;
                    border-radius   : 2px;
                    // border: solid 2px #d0c393;
                    text-decoration : none;
                    background-color: rgba(181, 145, 51, 0.8);
                    position        : absolute;
                    bottom          : 17%;
                    font-family     : 'Helvetica Neue';
                    font-style      : normal;
                    font-weight     : bold;
                    font-size       : 18px;
                    line-height     : 22px;
                    letter-spacing  : 0.1em;
                    text-transform  : uppercase;
                    color           : #FFFFFF;
                    display         : flex;
                    justify-content : center;
                    align-items     : center;
                    visibility      : hidden;
                    opacity         : 0;
                    transition      : visibility 0s, opacity .5s linear;

                    @media screen and (max-width: 767px) {
                        visibility: visible;
                        opacity   : 1;
                    }
                }
            }
        }
    }
}

.section-gallery {
    margin-bottom : 50px;
    padding-bottom: 50px;

    @media screen and (max-width: 991px) {
        margin-bottom : 40px;
        padding-bottom: 40px;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }

    .container {
        padding: 0;

        .gallery-description {
            font-size  : 14px;
            font-weight: 300;
            color      : #000000;
            text-align : center !important;
            margin     : 0 15px 15px 15px;

            p {
                text-align: center !important;
            }

            @media screen and (max-width: 991px) {
                font-size: 12px;
            }
        }

        .slick-gallery-carousel {
            .slick-item:focus {
                outline: none;
            }

            .slick-item {
                margin    : 0 10px;
                height    : 70vh;
                opacity   : 0.5;
                transition: opacity .5s;
                position  : relative;

                @media screen and (max-width: 991px) {
                    width: 400px;
                }

                @media screen and (max-width: 767px) {
                    margin : 0;
                    opacity: 1;
                    height : 400px;
                }

                img {
                    height         : 100%;
                    object-fit     : cover;
                    object-position: bottom center;
                }

                .overlay {
                    position        : absolute;
                    bottom          : 0;
                    height          : 15%;
                    width           : 100%;
                    background-color: rgba(1, 70, 53, 0.8);
                    display         : none;
                    align-items     : center;
                    justify-content : space-between;
                    padding         : 0px 30px;

                    .gallery-name {
                        font-family   : 'Comfortaa', cursive;
                        font-size     : 16px;
                        color         : #ffffff;
                        height        : 100%;
                        align-items   : center;
                        display       : flex;
                        text-transform: uppercase;
                    }

                    .arrows {
                        i {
                            font-size: 20px;
                            color    : #fff;
                            cursor   : pointer;
                        }
                    }
                }
            }

            .slick-current {
                opacity: 1;

                .overlay {
                    display: flex;
                }
            }
        }
    }
}

.section-video-background {
    background-image   : url('../../../../files/static/pattern2.jpg');
    height             : 100%;
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : cover;

    .section-video-residential {
        padding-top                    : 40px;
        // @media screen and (max-width: 991px) {
        //     margin-bottom : 40px;
        //     padding-bottom: 40px;
        // }

        @media screen and (max-width: 767px) {
            margin-bottom: 0;
        }

        .container {
            position: relative;

            .title {
                font-family          : 'Helvetica Neue', sans-serif;
                font-style           : normal;
                font-weight          : 500;
                font-size            : 36px;
                line-height          : 44px;
                text-align           : center;
                text-transform       : uppercase;
                font-feature-settings: 'cpsp'on;
                color                : #FFFFFF;
                margin-bottom        : 40px;

                @media screen and (max-width: 991px) {
                    font-size    : 20px;
                    margin-bottom: 30px;
                }
            }

            .arrows {
                position: absolute;
                bottom  : 41.5%;
                cursor  : pointer;

                @media screen and (max-width: 767px) {
                    display: none;
                }
            }

            .video-arrow-left {
                left: 70px;

                @media screen and (max-width: 1199px) {
                    left: 15px;
                }

                @media screen and (max-width: 991px) {
                    left: 5px;
                }
            }

            .video-arrow-right {
                right: 70px;

                @media screen and (max-width: 1199px) {
                    right: 15px;
                }

                @media screen and (max-width: 991px) {
                    right: 5px;
                }
            }

            .youtube-video {
                display        : flex;
                justify-content: center;
                height         : 600px;
                width          : 90%;
                margin         : auto;

                @media screen and (max-width: 767px) {
                    width: 100%;
                }

                @media screen and (max-width: 575px) {
                    height: 250px;
                }

                .content-video {
                    width    : 100%;
                    max-width: 100%;
                }

                .slick-dots {
                    margin: -10px 0px;

                    li {
                        button:before {
                            font-size: 15px;
                            color    : #FFFFFF;
                            opacity  : 1 !important;
                        }
                    }

                    .slick-active {
                        button:before {
                            font-size: 15px;
                            color    : #B59233;
                            opacity  : 1 !important;
                        }
                    }
                }

                .slick-list {
                    .slick-track {
                        height: 100%;

                        .content-video {
                            height: 100%;
                            width : 100%;

                            iframe {
                                height: 100%;
                                width : 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .section-video-office {
        // margin-bottom : 50px;
        // padding-bottom: 50px;
        padding-top   : 70px;
        padding-bottom: 50px;

        @media screen and (max-width: 767px) {
            margin-bottom: 0;
        }

        .container {
            position: relative;

            .title {
                font-family          : 'Helvetica Neue', sans-serif;
                font-style           : normal;
                font-weight          : 500;
                font-size            : 36px;
                line-height          : 44px;
                text-align           : center;
                text-transform       : uppercase;
                font-feature-settings: 'cpsp'on;
                color                : #FFFFFF;
                margin-bottom        : 40px;

                @media screen and (max-width: 991px) {
                    font-size    : 20px;
                    margin-bottom: 30px;
                }
            }

            .arrows {
                position: absolute;
                bottom  : 41.5%;
                cursor  : pointer;

                @media screen and (max-width: 767px) {
                    display: none;
                }
            }

            .video-arrow-left {
                left: 70px;

                @media screen and (max-width: 1199px) {
                    left: 15px;
                }

                @media screen and (max-width: 991px) {
                    left: 5px;
                }
            }

            .video-arrow-right {
                right: 70px;

                @media screen and (max-width: 1199px) {
                    right: 15px;
                }

                @media screen and (max-width: 991px) {
                    right: 5px;
                }
            }

            .youtube-video-office {
                display        : flex;
                justify-content: center;
                height         : 600px;
                width          : 90%;
                margin         : auto;

                @media screen and (max-width: 767px) {
                    width: 100%;
                }

                @media screen and (max-width: 575px) {
                    height: 250px;
                }

                .content-video {
                    width    : 100%;
                    max-width: 100%;
                }

                .slick-dots {
                    margin: -10px 0px;

                    li {
                        button:before {
                            font-size: 15px;
                            color    : #c4c4c4;
                            opacity  : 1 !important;
                        }
                    }

                    .slick-active {
                        button:before {
                            font-size: 15px;
                            color    : #014635;
                            opacity  : 1 !important;
                        }
                    }
                }

                .slick-list {
                    .slick-track {
                        height: 100%;

                        .content-video {
                            height: 100%;
                            width : 100%;

                            iframe {
                                height: 100%;
                                width : 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}



.slick-carousel-homepage {
    @media screen and (max-width: 575px) {
        width : 90%;
        margin: auto;
    }

    .slick-arrow {
        width    : 20px;
        height   : 40px;
        opacity  : 0.6;
        font-size: 40px;
        color    : #767676;
    }

    .slick-arrow:hover {
        color: #989898;
    }

    .slick-arrow:active {
        color: #989898;
    }

    .slick-arrow::before {
        content: none;
    }

    .slick-list {
        .slick-track {
            .slick-item {
                display        : flex;
                justify-content: center;
            }
        }
    }
}

.section-maps-news {
    background-image   : url('../../../../files/static/pattern3.jpg');
    height             : 100%;
    background-position: center;
    background-repeat  : no-repeat;
    background-size    : cover;
    padding-bottom     : 1rem;

    .section-maps {
        @media screen and (max-width: 991px) {
            margin-bottom : 40px;
            padding-bottom: 40px;
        }

        @media screen and (max-width: 767px) {
            margin-bottom: 0;
        }

        .container {
            height     : 90vh;
            display    : flex;
            align-items: center;

            @media screen and (max-width: 767px) {
                flex-direction: column;
                height        : unset;
                margin-top    : 10px;
            }

            .left-side {
                flex           : 1;
                display        : flex;
                flex-direction : column;
                justify-content: center;

                @media screen and (max-width: 767px) {
                    padding: 20px 40px;
                }

                .location-details {
                    .location-title {
                        font-family          : 'Helvetica Neue', sans-serif;
                        font-style           : normal;
                        font-weight          : 500;
                        font-size            : 36px;
                        line-height          : 44px;
                        text-transform       : uppercase;
                        font-feature-settings: 'cpsp'on;
                        color                : #B59233;
                        margin-bottom        : 50px;

                        @media screen and (max-width: 767px) {
                            text-align   : center;
                            margin-bottom: 10px;
                            color        : #FFFFFF;
                            font-weight  : bold;
                            font-size    : 18px;
                            line-height  : 22px;
                            color        : #FFFFFF
                        }
                    }

                    .location-description {
                        font-family  : 'Helvetica Neue', sans-serif;
                        font-style   : normal;
                        font-weight  : 500;
                        font-size    : 16px;
                        line-height  : 22px;
                        color        : #FFFFFF;
                        text-align   : left;
                        padding-right: 30px;

                        @media screen and (max-width: 767px) {
                            padding-right: unset;
                            text-align   : center;
                            font-style   : normal;
                            font-weight  : normal;
                            font-size    : 11px;
                            line-height  : 15px;
                        }
                    }
                }
            }

            .right-side {
                flex      : 1;
                flex-basis: 50%;

                .location-map {
                    height: 60vh;
                    width : 90%;
                    margin: auto;

                    @media screen and (max-width: 767px) {
                        height: 50vh;
                        width : 100%;
                    }

                    @media screen and (max-width: 375px) {
                        width: 100%;
                    }

                    @media screen and (max-width: 320px) {
                        width: 100%;
                    }
                }

                iframe {
                    width: 100%;
                }
            }

            // justify-content: center;
            // flex-direction: column;
            // align-items: center;
            // @media screen and (min-width: 1200px) {
            //     height: 110vh;
            // }
            // img {
            //     width: 100%;
            // }
        }
    }


    .section-news-events {
        margin-bottom: 60px;

        @media screen and (max-width: 991px) {
            margin-bottom: 50px;
        }

        @media screen and (max-width: 375px) {
            margin-top: 30px;
        }

        @media screen and (max-width: 360px) {
            margin-top: 70px;
        }

        @media screen and (max-width: 320px) {
            margin-top: 120px;
        }

        @media screen and (max-width: 280px) {
            margin-top: 170px;
        }

        .container {
            .title {
                font-family          : 'Helvetica Neue', sans-serif;
                font-style           : normal;
                font-weight          : 500;
                font-size            : 36px;
                line-height          : 44px;
                text-transform       : uppercase;
                font-feature-settings: 'cpsp'on;
                color                : #B59233;
                margin-bottom        : 40px;

                @media screen and (max-width: 575px) {
                    font-weight: bold;
                    font-size  : 18px;
                    line-height: 22px;
                    text-align : center;
                    color      : #FFFFFF;
                }

            }

            .slick-carousel-news {
                display        : flex;
                justify-content: center;
                align-items    : center;

                @media screen and (max-width: 767px) {
                    width : 100%;
                    height: 360px;
                }

                .slick-list {
                    height: 100%;
                    width : 100%;

                    .slick-item {
                        width: 100%;
                    }
                }

                .slick-dots {
                    margin: -10px 0px;
                    li {
                        button:before {
                            font-size: 15px;
                            color: #FFFFFF;
                            opacity: 1!important;
                        }
                    }
                    .slick-active {
                        button:before {
                            font-size: 15px;
                            color: #B59233;
                            opacity: 1!important;
                        }
                    }
                }
            }

            .news-events {
                display        : flex;
                justify-content: space-evenly;
                margin-right   : 20px;
                flex-direction : column;
                position       : relative;

                @media screen and (max-width: 767px) {
                    margin: 0px;
                }

                .news-event {
                    // background-size: cover;
                    // background-position: center center;
                    background-color   : rgba(196, 196, 196, 0.21);
                    position           : relative;
                    width              : 30vw;
                    height             : 50vh;
                    background-position: center;
                    background-size    : cover;

                    @media screen and (max-width: 1199px) {
                        width: 305px;
                    }

                    @media screen and (max-width: 991px) {
                        width : 220px;
                        height: 200px;
                    }

                    @media screen and (max-width: 767px) {
                        width : 100%;
                        height: 325px;
                    }

                    .date {
                        font-size       : 12px;
                        font-weight     : bold;
                        color           : #d0c393;
                        margin-top      : 10px;
                        padding         : 8px 15px;
                        background-color: #123a37;
                        width           : max-content;

                        @media screen and (max-width: 991px) {
                            font-size: 10px;
                        }
                    }
                }

                .teaser {
                    width           : 100%;
                    height          : 130px;
                    background-color: rgba(196, 196, 196, 0.21);
                    position        : absolute;
                    bottom          : 0;
                    display         : flex;
                    justify-content : center;
                    padding         : 7px;
                    flex-direction  : column;

                    .teaser-text {
                        font-family: 'Helvetica Neue', sans-serif;
                        font-style : normal;
                        font-size  : 18px;
                        font-weight: bold;
                        text-align : center;
                        color      : #FFFFFF;
                        padding    : 0 10px;
                        height     : 100%;
                        width      : 100%;
                        margin-top : 30px;

                        @media screen and (max-width: 991px) {
                            font-size: 11px;
                        }

                        @media screen and (max-width: 575px) {
                            font-size: 10px;
                            padding  : 0 5px;
                        }
                    }

                    .read-more {
                        width           : 167px;
                        height          : 49px;
                        border-radius   : 2px;
                        border          : solid 2px #d0c393;
                        background-color: #014635;
                        font-size       : 14px;
                        font-weight     : bold;
                        letter-spacing  : -0.2px;
                        text-align      : center;
                        color           : #d0c393;
                        cursor          : pointer;
                        position        : absolute;
                        bottom          : -24px;
                        display         : flex;
                        justify-content : center;
                        align-items     : center;
                        text-decoration : none;

                        @media screen and (max-width: 1199px) {
                            width    : 100px;
                            font-size: 13px;
                        }

                        @media screen and (max-width: 575px) {
                            width    : 150px;
                            font-size: 12px;
                        }
                    }

                    .read-id {
                        width: 167px;

                        @media screen and (max-width: 1199px) {
                            width    : 145px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}


.section-features {
    padding-bottom: 50px;

    @media screen and (max-width: 575px) {
        padding-bottom: 0;
    }

    .container {
        height         : 100%;
        display        : flex;
        justify-content: center;
        flex-direction : column;

        @media screen and (max-width: 575px) {
            padding: 0;
        }

        .feature-block {
            display        : flex;
            justify-content: center;
            flex-wrap      : no-wrap;
            width          : 100%;
            padding-top    : 50px;
            flex-direction : row;
            align-items    : center;

            @media screen and (max-width: 767px) {
                display: block;
            }

            .slick-dots {
                margin: -10px 0px;

                li {
                    button:before {
                        font-size: 15px;
                        color    : #c4c4c4;
                        opacity  : 1 !important;
                    }
                }

                .slick-active {
                    button:before {
                        font-size: 15px;
                        color    : #014635;
                        opacity  : 1 !important;
                    }
                }
            }

            .order-left {
                order: 1;

                @media screen and (max-width: 575px) {
                    order: 0;
                }
            }

            .order-right {
                order          : 2;
                justify-content: flex-end;

                @media screen and (max-width: 575px) {
                    order: 0;
                }
            }

            .image-div {
                flex          : 60%;
                min-width     : 0px;
                display       : flex;
                align-content : flex-end;
                flex-direction: row;

                @media screen and (max-width: 767px) {
                    display: block;
                }

                .image-block {
                    img {
                        height         : 24vw;
                        object-fit     : cover;
                        object-position: bottom center;
                        margin-right   : 18px;

                        @media screen and (max-width: 1199px) {
                            height: 28vw;
                        }

                        @media screen and (max-width: 991px) {
                            height: 205px;
                        }

                        @media screen and (max-width: 767px) {
                            height: 300px;
                        }

                        @media screen and (max-width: 575px) {
                            margin-right: unset;
                        }
                    }
                }
            }

            .description-block {
                flex: 40%;

                @media screen and (max-width: 767px) {
                    align-self    : center;
                    text-align    : center;
                    padding-top   : 30px;
                    padding-bottom: 20px;
                }

                .title {
                    // @include property-map("font-size", $description-font-size);
                    font-family   : 'Helvetica Neue', sans-serif;
                    font-size     : 36px;
                    font-weight   : 500;
                    color         : #B59233;
                    font-style    : normal;
                    text-transform: uppercase;
                    text-align    : left;
                    line-height   : 44px;

                    @media screen and (max-width: 575px) {
                        text-align : center;
                        font-weight: bold;
                        font-size  : 18px;
                        line-height: 22px;
                        color      : #B08D23;
                    }
                }

                .title:after {
                    content: "";
                    /* This is necessary for the pseudo element to work. */
                    display: block;
                    /* This will put the pseudo element on its own line. */
                    width  : 30px;
                    /* Change this to whatever width you want. */
                    margin : 10px 0px;

                    /* This creates some space between the element and the border. */
                    // border-bottom: 4px solid #59652a; /* This creates the border. Replace black with whatever color you want. */
                    @media screen and (max-width: 575px) {
                        margin: 10px auto;
                    }
                }

                .description {
                    // @include property-map("font-size", $description-font-size);
                    font-family  : 'Helvetica Neue', sans-serif;
                    font-weight  : 500;
                    color        : #575757;
                    font-style   : normal;
                    font-size    : 16px;
                    line-height  : 22px;
                    margin-bottom: 40px;

                    @media screen and (max-width: 575px) {
                        padding      : 0px 30px;
                        text-align   : center;
                        font-weight  : normal;
                        font-size    : 11px;
                        line-height  : 15px;
                        color        : #393939;
                        margin-bottom: 30px;
                    }
                }

                .more-details {
                    @media screen and (max-width: 575px) {
                        text-align: center;
                    }

                    a {
                        text-decoration : none;
                        font-family     : 'Helvetica Neue';
                        font-style      : normal;
                        font-weight     : bold;
                        font-size       : 18px;
                        line-height     : 22px;
                        text-align      : center;
                        letter-spacing  : 0.1em;
                        text-transform  : uppercase;
                        color           : #FFFFFF;
                        background-color: rgba(44, 56, 78, 0.7);
                        display         : inline-block;
                        padding         : 0.8em 1.3em;
                        margin          : 0 0.3em 0.3em 0;
                        width           : 207px;
                        height          : 50px;

                        @media screen and (max-width: 575px) {
                            font-size  : 13px;
                            line-height: 16px;
                            width      : 157px;
                            height     : 35px;
                        }
                    }

                    a:hover {
                        background-color: rgba(176, 141, 35, 0.8);
                    }
                }
            }

            .description-block.order-left {
                text-align: right;
            }

            .description-block.order-right {
                text-align: left;
            }
        }
    }
}