$hero-title-font-size: (null: 40px, $md: 30px, $sm: 28px, $xs: 25px);

.section-hero {
    min-height: em(315);
    margin-bottom: em(30);
    @media screen and (min-width: 991px) {
        height: em(700);
        margin-bottom: em(50);
    }
    .hero-header {
        height: 100%;
        img {
            height: 100%;
            object-position: top center;
            object-fit: cover;
        }
    }
    .owl-carousel {
        display: flex;
        height: 100%;
        background-color: #ffffff;
        img {
            width: 100%;
            display: block;
        }
        .owl-stage-outer {
            height: 100%;
            @media screen and (max-width: 575px) {
                max-height: em(315);
            }
            .owl-stage {
                display: flex;
                height: 100%;
                .owl-item {
                    img {
                        width: 100%;
                        height: 100%;
                        object-position: top center;
                        object-fit: cover;
                    }
                }
            }
        }
        
        .owl-nav {
            position: absolute;
            top: unset;
            width: unset;
            bottom: em(200);
            opacity: 1;
            left: 100px;
            @media screen and (max-width: 1024px){
                bottom: em(200);
                left: em(40);
            }
            @media screen and (max-width: 768px) {
                left: em(312);
            }
            @media screen and (max-width: 575px) {
                bottom: em(19);
                left: 40%!important;
            }
            button.owl-next {
                background: 0 0;
                color: inherit;
                border: none;
                padding: 0!important;
            }
            button.owl-prev {
                background: 0 0;
                color: inherit;
                border: none;
                padding: 0!important;
            }
            
            button:focus {
                outline: none;
            }
            img {
                width: em(50);
                height: em(50);
                @media screen and (max-width: 575px) {
                    width: em(32);
                    height: em(32);
                }
            }
        }
        .owl-dots {
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            position: absolute;
            button.owl-dot {
                background: 0 0;
                color: inherit;
                border: none;
                padding: 0!important;
                span{
                    width: 15px;
                    height: 15px;
                    margin: 30px 7px;
                    background: #FFFFFF;
                    display: block;
                    -webkit-backface-visibility: visible;
                    transition: opacity .2s ease;
                    border-radius: 30px;
                }
                &.active{
                    span{
                        background: #B59233 !important;
                     }
                }
                &:hover{
                    span{
                        background: #B59233;
                    }
                }
            }
        }
        .owl-prev {
            left: unset;
        }
        .owl-next {
            right: unset;
            margin-left: em(10);
        }

    }

    //to be deprecated
    .carousel {
        height: 100%;
        background-color: #000;
        width: 100%;
        @media screen and (max-width: 575px) {
            height: em(315);
        }
        .carousel-inner {
            height: inherit !important;
            .carousel-item {
                height: 100%;
                img {
                    height: 100%;
                    object-position: center center;
                    object-fit: cover;
                }
            }
        }
    }
    .carousel-arrows {
        top: unset;
        width: unset;
        bottom: 200px;
        opacity: 1;
        @media screen and (max-width: 991px) {
            bottom: em(160);
        }
        @media screen and (max-width: 767px) {
            bottom: em(30);
            left: em(20) !important;
        }
        img {
            width: em(50);
            height: em(50);
            @media screen and (max-width: 575px) {
                width: em(32);
                height: em(32);
            }
        }
    }
    .carousel-control-prev {
        left: unset;
    }
    .carousel-control-next {
        right: unset;
        margin-left: 65px;
    }
    //end

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fcfaed;
        @media screen and (min-width: 576px) {
            background-color: unset;
        }
        .infos {
            padding: em(15);
            opacity: 0.9;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (min-width: 576px) {
                position: absolute;
                top: em(350);
                z-index: 59;
                align-items: flex-start;
                left: 2%;
            }
            .hero-title {
                color: #ffffff;
                margin-bottom: em(20);
                font-family: 'Comfortaa', cursive;
                text-shadow: 2px 2px 5px #000000;
                @include property-map("font-size", $hero-title-font-size);
                @media screen and (max-width: 575px) {
                    color: #014635;
                }
            }
            %hero-brochure {
                display: flex;
                justify-content: center;
                align-items: center;
                width: em(250);
                height: em(50);
                border-radius: em(2);
                font-style: normal;
                // border: solid em(2) #d0c393;
                font-family: 'Helvetica Neue', sans-serif;
                background-color: rgba(109, 140, 162, 0.8);
                // font-size: em(14);
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.1em;
                font-weight: bold;
                color: #FFFFFF;
                text-decoration: none;
            }
            .hero-box {
                z-index: 9;
                display: flex;
                flex-direction: row;
                bottom: 23%;
                align-items: center;
                align-self: center;
                @media screen and (max-width: 991px) {
                    flex-direction: column;
                    width: 80%;
                    bottom: unset;
                    top: em(240);
                    align-self: flex-start;
                }
                .hero-black-box {
                    display: flex;
                    @media screen and (max-width: 991px) {
                        width: 100%;
                        flex-direction: column;
                    }
                    .box-1 {
                        border-top-left-radius: em(5);
                        border-bottom-left-radius: em(5);
                        @media screen and (max-width: 991px) {
                            border-bottom-left-radius: unset;
                            border-top-right-radius: em(5);
                        }
                    }
                    .box-3 {
                        border-right: unset;
                        @media screen and (max-width: 991px) {
                            border-right: solid em(1) #d0c393;
                        }
                    }
                }
                .footer-box {
                    width: em(207);
                    height: em(84);
                    background-color: rgba(1, 70, 53, 0.8); 
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: solid em(1) #d0c393;
                    font-size: em(18);
                    font-weight: 300;
                    color: #ffffff;
                    @media screen and (max-width: 1199px) {
                        width: em(170);
                    }
                    @media screen and (max-width: 991px) {
                        width: 100%;
                        height: em(60);
                        line-height: em(23);
                        flex-direction: row;
                        justify-content: flex-start;
                        padding-left: em(20);
                        border-bottom: unset;
                    }
                    @media screen and (max-width: 575px) {
                        font-size: em(15);
                    }
                    strong {
                        font-weight: bold;
                        color: #fff;
                    }
                }
                .last-box {
                    width: em(280);
                    height: em(133);
                    background-color: #fff;
                    color: #808080;
                    border-radius: em(5);
                    border: solid em(1) #979797;
                    font-size: em(20);
                    color: #014635!important;
                    @media screen and (max-width: 991px) {
                        width: 100%;
                        height: em(80);
                        flex-direction: row;
                        align-items: center;
                        border-top-left-radius: unset;
                        border-top-right-radius: unset;
                        padding-left: em(20);
                    }
                    @media screen and (max-width: 575px) {
                        font-size: em(18);
                    }
                    .last-footer {
                        display: flex;
                        flex-direction: column;
                        @media screen and (max-width: 991px) {
                        }
                        .starting {
                            text-align: left;
                        }
                        .price {
                            font-size: em(30);
                            @media screen and (max-width: 575px) {
                                font-size: em(20);
                            }
                            strong {
                                font-weight: bold;
                                color: #014635;
                            }
                        }
                    }
                }
            }
        }
    }
}

.section-hero-2 {
    min-height: em(315);
    margin-bottom: em(30);
    @media screen and (min-width: 991px) {
        height: em(700);
        margin-bottom: em(50);
    }
    @media screen and (max-width: 575px){
        min-height: unset;
        margin-bottom: em(20);
    }
    .hero-header {
        height: 100%;
        img {
            height: 100%;
            object-position: top center;
            object-fit: cover;
        }
    }

    //to be deprecated
    .carousel {
        height: 100%;
        background-color: #000;
        width: 100%;
        @media screen and (max-width: 575px) {
            height: em(315);
        }
        .carousel-inner {
            height: inherit !important;
            .carousel-item {
                height: 100%;
                img {
                    height: 100%;
                    object-position: center center;
                    object-fit: cover;
                }
            }
        }
    }
    .carousel-arrows {
        top: unset;
        width: unset;
        bottom: 200px;
        opacity: 1;
        @media screen and (max-width: 991px) {
            bottom: em(160);
        }
        @media screen and (max-width: 767px) {
            bottom: em(30);
            left: em(20) !important;
        }
        img {
            width: em(50);
            height: em(50);
            @media screen and (max-width: 575px) {
                width: em(32);
                height: em(32);
            }
        }
    }
    .carousel-control-prev {
        left: unset;
    }
    .carousel-control-next {
        right: unset;
        margin-left: 65px;
    }
    //end

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #fcfaed;
        @media screen and (min-width: 576px) {
            background-color: unset;
        }
        .infos {
            padding: em(15);
            opacity: 0.9;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (min-width: 576px) {
                position: absolute;
                top: em(350);
                z-index: 59;
                align-items: flex-start;
                left: 2%;
            }
            @media screen and (max-width: 575px) {
                display: none
            }
            .hero-title {
                color: #ffffff;
                margin-bottom: em(20);
                font-family: 'Comfortaa', cursive;
                text-shadow: 2px 2px 5px #000000;
                @include property-map("font-size", $hero-title-font-size);
                @media screen and (max-width: 575px) {
                    color: #014635;
                }
            }
            %hero-brochure {
                display: flex;
                justify-content: center;
                align-items: center;
                width: em(180);
                height: em(50);
                border-radius: em(2);
                border: solid em(2) #d0c393;
                background-color: #014635;
                font-size: em(14);
                font-weight: bold;
                color: #d0c393;
                text-decoration: none;
            }
            .hero-box {
                z-index: 9;
                display: flex;
                flex-direction: row;
                bottom: 23%;
                align-items: center;
                align-self: center;
                @media screen and (max-width: 991px) {
                    flex-direction: column;
                    width: 80%;
                    bottom: unset;
                    top: em(240);
                    align-self: flex-start;
                }
                .hero-black-box {
                    display: flex;
                    @media screen and (max-width: 991px) {
                        width: 100%;
                        flex-direction: column;
                    }
                    .box-1 {
                        border-top-left-radius: em(5);
                        border-bottom-left-radius: em(5);
                        @media screen and (max-width: 991px) {
                            border-bottom-left-radius: unset;
                            border-top-right-radius: em(5);
                        }
                    }
                    .box-3 {
                        border-right: unset;
                        @media screen and (max-width: 991px) {
                            border-right: solid em(1) #d0c393;
                        }
                    }
                }
                .footer-box {
                    width: em(207);
                    height: em(84);
                    background-color: rgba(1, 70, 53, 0.8); 
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: solid em(1) #d0c393;
                    font-size: em(18);
                    font-weight: 300;
                    color: #ffffff;
                    @media screen and (max-width: 1199px) {
                        width: em(170);
                    }
                    @media screen and (max-width: 991px) {
                        width: 100%;
                        height: em(60);
                        line-height: em(23);
                        flex-direction: row;
                        justify-content: flex-start;
                        padding-left: em(20);
                        border-bottom: unset;
                    }
                    @media screen and (max-width: 575px) {
                        font-size: em(15);
                    }
                    strong {
                        font-weight: bold;
                        color: #fff;
                    }
                }
                .last-box {
                    width: em(280);
                    height: em(133);
                    background-color: #fff;
                    color: #808080;
                    border-radius: em(5);
                    border: solid em(1) #979797;
                    font-size: em(20);
                    color: #014635!important;
                    @media screen and (max-width: 991px) {
                        width: 100%;
                        height: em(80);
                        flex-direction: row;
                        align-items: center;
                        border-top-left-radius: unset;
                        border-top-right-radius: unset;
                        padding-left: em(20);
                    }
                    @media screen and (max-width: 575px) {
                        font-size: em(18);
                    }
                    .last-footer {
                        display: flex;
                        flex-direction: column;
                        @media screen and (max-width: 991px) {
                        }
                        .starting {
                            text-align: left;
                        }
                        .price {
                            font-size: em(30);
                            @media screen and (max-width: 575px) {
                                font-size: em(20);
                            }
                            strong {
                                font-weight: bold;
                                color: #014635;
                            }
                        }
                    }
                }
            }
        }
    }
}