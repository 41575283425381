// body font size
$browser-context: 16;



// unitless function to convert px to rem
@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
}

// breakpoint mixin
@mixin breakpoint($mq01: 0, $mq2: false, $maxmin: max-width) {
    @if $mq2 == false {
        @media ($maxmin: $mq01) {
            @content;
        }
    }
    @else {
        @media (min-width: $mq01) and (max-width: $mq2) {
            @content;
        }
    }
}

// breakpoint variables
$lg: 1199.98px;
$md: 991.98px;
$sm: 767.98px;
$xs: 575.98px;

// responsive property mixin
@mixin property-map($property, $font-size-map) {
    @each $breakpoint, $font-size in $font-size-map {
        @if $breakpoint == null {
            #{$property}: $font-size;
        } @else {
            @include breakpoint($breakpoint) {
                #{$property}: $font-size;
            }
        }
    }
}

//repeating
$title-font-size: (null: 22px, $md: 22px, $sm: 18px, $xs: 15px);
$description-font-size: (null: 14px, $md: 14px, $sm: 13px, $xs: 13px);
