.section-footer-form {
    height             : 100%;
    background-color   : #B59233;
    background-size    : cover;
    background-position: center center;
    padding: 25px 0;
    @media screen and (max-width: 991px) {
        height: unset;
    }

    .container {
        height: 100%;

        .download-brochure {
            display        : none;
            justify-content: space-around;
            align-items    : center;
            flex-flow: wrap;

            @media screen and (max-width: 991px) {
                padding: 25px 0px;

            }

            @media screen and (max-width: 767px) {
                padding: 15px 0px;
            }
            .boltform-info {
                margin: 10px auto;
            }
      .break {
          display: flex;
          flex-basis: 100%;
      }
            a {
                flex-basis              : 15%;
                height             : 65px;
                border-radius      : 2px;
                // border          : solid 2px #d0c393;
                background-color   : #2C384E;
                font-size          : 18px;
                font-weight        : bold;
                color              : #FFFFFF;
                text-align         : center;
                padding            : 3px 4px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: 991px) {
                    flex-basis: 45%;
                    margin-top: 8px;

                }
            }:last-child {
                margin-right: unset;
            }

            .form-title {
                font-size     : 22px;
                font-weight   : bold;
                letter-spacing: -0.4px;
                color         : #ffffff;
                margin-bottom : 20px;
            }

            .boltform {
                @media screen and (max-width: 991px) {
                    width: 100%;
                }

                .download-form {
                    display         : flex;
                    align-items     : center;
                    border-radius   : 5px;
                    width           : 100%;
                    background-color: rgba(208, 195, 147, 1);
                    justify-content : center;
                    padding         : 10px;

                    @media screen and (max-width: 991px) {
                        flex-direction: column;
                        border-radius : 2px;
                        padding       : 30px 25px;
                    }

                    @media screen and (max-width: 767px) {
                        padding: 23px 25px;
                        width  : 95%;
                        margin : auto;
                    }

                    .boltforms-row {
                        margin: 0px 2px;

                        @media screen and (max-width: 991px) {
                            width         : 100%;
                            padding-bottom: 10px;
                        }
                        #footer-form_project_choice {
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                            background-repeat: no-repeat;
                            background-position-x: 95%;
                            background-position-y: 50%;
                            width           : 211px;
                            height          : 50px;
                            border          : none;
                            border-radius   : 2px;
                            background-color: rgba(255, 255, 255, 1);
                            padding-left    : 10px;
                            font-size       : 16px;
                            font-weight     : bold;
                            color           : #666666;
                            line-height     : 22px;
                            font-family     : 'Helvetica Neue', sans-serif;
                            @media screen and (max-width: 991px) {
                                width: 100%;
                            }
                          }
                        input, select {
                            width           : 211px;
                            height          : 45px;
                            border          : none;
                            border-radius   : 2px;
                            background-color: rgba(255, 255, 255, 1);
                            padding-left    : 10px;
                            font-size       : 14px;
                            font-weight     : 300;
                            color           : #4a4a4a;
                            @media screen and (max-width: 991px) {
                                width: 100%;
                            }
                        }

                        input[type=number]::-webkit-inner-spin-button,
                        input[type=number]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin            : 0;
                        }

                        input[type=number] {
                            -moz-appearance: textfield;
                        }

                        button {
                            width           : 121px;
                            height          : 43px;
                            border-radius   : 2px;
                            border          : solid 2px #d0c393;
                            background-color: #014635;
                            font-size       : 14px;
                            font-weight     : bold;
                            color           : #d0c393;

                            @media screen and (max-width: 991px) {
                                width: 20%;
                            }

                            @media screen and (max-width: 767px) {
                                width: 40%;
                            }
                        }
                    }

                    .submit-but-row {
                        @media screen and (max-width: 991px) {
                            display        : flex;
                            justify-content: center;
                            padding-bottom : 0px;
                        }
                    }
                }
            }
        }

        .footer-form {
            height         : 100%;
            display        : flex;
            justify-content: center;
            align-items    : center;
            flex-direction : column;


            // @media screen and (max-width: 991px) {
            //     padding: 50px 0px;
            // }

            // @media screen and (max-width: 767px) {
            //     padding: 50px 0px;
            // }

            .form-title {
                font-size     : 36px;
                font-weight   : bold;
                line-height   : 44px;
                font-style    : normal;
                text-transform: uppercase;
                letter-spacing: -0.4px;
                color         : #ffffff;
                margin-bottom : 20px;
                text-align: center;
                @media screen and (max-width: 767px) {
                    font-size: 20px;
                    line-height: 25px;
                }
            }

            .boltform {
                @media screen and (max-width: 991px) {
                    width: 100%;
                }

                .footer-form-submit {
                    display            : flex;
                    align-items        : center;
                    border-radius      : 5px;
                    width              : 100%;
                    // background-color: rgba(208, 195, 147, 1);
                    justify-content    : center;
                    padding            : 10px;

                    @media screen and (max-width: 991px) {
                        flex-direction: column;
                        border-radius : 2px;
                        padding       : 30px 25px;
                    }

                    @media screen and (max-width: 767px) {
                        padding: 23px 25px;
                        width  : 95%;
                        margin : auto;
                    }

                    .boltforms-row {
                        margin: 0px 2px;
                        
                        @media screen and (max-width: 991px) {
                            width         : 100%;
                            padding-bottom: 10px;
                        }
                        #footer-form_project_choice {
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                            background-repeat: no-repeat;
                            background-position-x: 95%;
                            background-position-y: 50%;
                            width           : 211px;
                            height          : 50px;
                            border          : none;
                            border-radius   : 2px;
                            background-color: rgba(255, 255, 255, 1);
                            padding-left    : 10px;
                            font-size       : 16px;
                            font-weight     : bold;
                            color           : #666666;
                            line-height     : 22px;
                            font-family     : 'Helvetica Neue', sans-serif;
                            @media screen and (max-width: 991px) {
                                width: 100%;
                            }
                          }
                        input, select {
                            width           : 211px;
                            height          : 50px;
                            border          : none;
                            border-radius   : 2px;
                            background-color: rgba(255, 255, 255, 1);
                            padding-left    : 10px;
                            font-size       : 16px;
                            font-weight     : bold;
                            color           : #666666;
                            line-height     : 22px;
                            font-family     : 'Helvetica Neue', sans-serif;
                            @media screen and (max-width: 991px) {
                                width: 100%;
                            }
                        }
                        


                        input[type=number]::-webkit-inner-spin-button,
                        input[type=number]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin            : 0;
                        }

                        input[type=number] {
                            -moz-appearance: textfield;
                        }

                        button {
                            width              : 121px;
                            height             : 50px;
                            border-radius      : 2px;
                            // border          : solid 2px #d0c393;
                            background-color   : #2C384E;
                            font-family        : 'Helvetica Neue', sans-serif;
                            font-style         : normal;
                            font-weight        : bold;
                            font-size          : 16px;
                            line-height        : 22px;
                            /* identical to box height, or 141% */


                            color: #FFFFFF;


                            @media screen and (max-width: 991px) {
                                width: 20%;
                            }

                            @media screen and (max-width: 767px) {
                                width: 40%;
                            }
                        }
                    }

                    .submit-but-row {
                        @media screen and (max-width: 991px) {
                            display        : flex;
                            justify-content: center;
                            padding-bottom : 0px;
                        }
                    }
                }
            }
        }
    }
}